<template>
  <van-popup v-model="showPicker" round position="bottom">
    <van-datetime-picker
      v-model="currentDate"
      type="date"
      :min-date="minDate"
      :max-date="maxDate"
      @confirm="onConfirm"
      @cancel="showPicker = false"
    />
  </van-popup>
</template>
<script>
import { dateFormat } from "@/utils/tool";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => ({
        currentDate: new Date("2015-01-01"),
        minDate: new Date("2000-01-01"),
        maxDate: new Date()
      })
    }
  },
  data() {
    return {
      currentDate: this.config.currentDate || new Date("2015-01-01"),
      minDate: this.config.minDate || new Date("2000-01-01"),
      maxDate: this.config.maxDate || new Date()
    };
  },
  watch: {
    config: {
      deep: true,
      handler(v) {
        const { currentDate, minDate, maxDate } = v;
        if (currentDate) this.currentDate = new Date(Date.parse(currentDate));
        if (minDate) this.minDate = new Date(Date.parse(minDate));
        if (maxDate) this.maxDate = new Date(Date.parse(maxDate));
      }
    }
  },
  computed: {
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        if (!v) this.currentDate = "";
        this.$emit("input", v);
      }
    }
  },
  methods: {
    onConfirm(v) {
      this.showPicker = false;
      const date = dateFormat(v, "YYYY-MM-DD");
      this.$emit("confirm", date);
    }
  }
};
</script>
